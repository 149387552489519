<template>
  <div>
    <a-card title="更新日志">
      <div>
        <div class="title">2024-04-08</div>
        <div class="content">
          <div>1. 增加更新日志功能</div>
          <div>2. BOM管理新增复制功能</div>
          <div>3. BOM管理增加 是否为基础BOM 字段</div>
          <div>4. BOM管理增加 是否同步子物料 字段</div>
          <div>5. 生产排产增加 操作人 字段</div>
          <div>6. 生产报工增加 不良品数量 字段</div>
          <div>7. 移动端增加生产任务菜单</div>
          <div>8. 工艺路线增加 关联工序字段</div>
        </div>
        <!-- <a-divider /> -->
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
}

.content {
  margin-top: 12px;
  padding: 0 24px;
  font-size: 16px;
}
</style>
